import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from './context/AuthContext.js';
import { ClientProvider } from './context/ClientContext.js';
import ProtectedRoute from './ProtectedRoute.js';
import LoginPage from './pages/LoginPage.js';
import DashBoardPage from './pages/DashBoardPage.js';
import NotFoundPage from './NotFoundPage.js';

const QrMenu = () => {
  return (
    <AuthProvider>
        <ClientProvider>
            <BrowserRouter basename='/'>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="*" element={<NotFoundPage />} />

                    <Route element={<ProtectedRoute />}>
                      <Route path="/" element={<DashBoardPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </ClientProvider>
    </AuthProvider>
  );
}

export default QrMenu;