import './Footer.css';

const Footer = () => {
    return(
        <div className='footer'>
            <a className="href-imagen" href="https://pido-online.com.ar/" target='_blank'><img src='./images/footer.svg' alt='logo pido-online' /></a>
        </div>
    );
}

export default Footer;
