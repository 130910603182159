import './css/LoginPage.css'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import { useAuth } from '../context/AuthContext.js'
import { useEffect } from 'react'
import { Spinner } from 'reactstrap';

function LoginPage() {
    const {register, handleSubmit, formState: {errors}} = useForm()
    const {signIn, isAuthenticated, errors: loginErrors, clearErrors, loading, loadingLogin} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        clearErrors()
    }, [])

    useEffect(() => {
        if(isAuthenticated) navigate('/')
    }, [isAuthenticated])

    const onSubmit = handleSubmit(async (values) => {
        signIn(values)
    })

    if(loading) return(
        <div className='spinnerContainerLogin'>
              <Spinner />
        </div>
    )

    return (
        <div className='login-page'>
            <div className='container'>
                <form className='row' onSubmit={onSubmit}>
                    <img src='images/login.svg' alt='logo' />
                    <p className='login-page-input-title'>Usuario</p>
                    <div className='input-field-login-username'>
                        <input 
                            type='text' 
                            placeholder='Nombre de usuario'
                            {...register('username', {required: true})} 
                        />
                    </div>
                    {errors.username && <p className='login-page-input-error'>El nombre de usuario es requerido</p>}
                    <p className='login-page-input-title login-page-input-title-password'>Contraseña</p>
                    <div className='input-field-login-password'>
                        <input 
                            type='password' 
                            placeholder='Contraseña'
                            {...register('password', {required: true, minLength: 6})} 
                        />
                    </div>
                    {errors.password && errors.password.type === 'required' && <p className='login-page-input-error'>La contraseña es requerida</p>}
                    {errors.password && errors.password.type === 'minLength' && <p className='login-page-input-error'>La contraseña debe tener al menos 6 carácteres</p>}
                    {loginErrors.length > 0 && <div className='login-page-errors'>
                        {loginErrors.map((error, i) => (
                            <div key={i}>{error.message}</div>
                        ))}
                    </div>
                    }
                    {loginErrors.message && <div className='login-page-errors'>
                        <div>{loginErrors.message}</div>
                    </div>
                    }
                    <button type='submit'>{!loadingLogin ? 'INGRESAR' : <Spinner />}</button>
                </form>
            </div>
        </div>
    )
}

export default LoginPage