import { useState, createContext, useContext } from "react";
import { getPluginRequest, getProfileRequest } from '../api/client.js'

const ClientContext = createContext()

export const useClients = () => {
    const context = useContext(ClientContext)
    if(!context) throw new Error("useClients must be used within an TaskProvider")

    return context
}

export const ClientProvider = ({children}) => {
    const [client, setClient] = useState(null)
    const [loadingProfile, setLoadingProfile] = useState(true)

    const getProfile = async () => {
        setLoadingProfile(true)
        try {
            const res = await getProfileRequest()
            setClient(res.data)
            setLoadingProfile(false)
            return res.data
        } catch(error) {
            console.error(error)
            setLoadingProfile(false)
            return false
        }
    }

    return(
        <ClientContext.Provider value={{getProfile, client, loadingProfile}}>
            {children}
        </ClientContext.Provider>
    )
}