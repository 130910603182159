import './css/DashBoardPage.css'
import { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import { useClients } from '../context/ClientContext.js'
import Navbar from "../Components/Navbar/Navbar.js"
import QRCode from "qrcode.react";
import Footer from '../Components/Footer/Footer.js'
import { format } from 'date-fns'

const DashBoardPage = () => {
    const { getProfile } = useClients()
    const [cliente, setCliente] = useState(null)
    const [loadingCliente, setLoadingCliente] = useState(true)

    useEffect(() => {
        const obtenerPerfil = async () => {
            setLoadingCliente(true)
            const perfil = await getProfile()
            setCliente(perfil)
            setLoadingCliente(false)
        }

        obtenerPerfil()
    }, [])

    const downloadQRCode = (e) => {
        e.preventDefault()
        const canvas = document.getElementById("qr-gen");
        const pngUrl = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qr-${cliente.username}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };

    return(
        <div className="dashboard">
            <Navbar />
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 pt-3 pb-3 pt-md-0 pb-md-0 dashboard-boton">
                        <a href={`https://pido-online.com.ar/${!loadingCliente ? cliente.username : ''}`}>
                            <img src="images/dashboard-boton1.jpg" alt="Ver carta" />
                        </a>
                    </div>
                    <div className="col-12 col-md-4 pt-3 pb-3 pt-md-0 pb-md-0 dashboard-boton">
                        <a href={!loadingCliente ? cliente.sheet : ''} target='_blank'>
                            <img src="images/dashboard-boton2.jpg" alt="Editar carta" />
                        </a>
                    </div>
                    <div className="col-12 col-md-4 pt-3 pb-3 pt-md-0 pb-md-0 dashboard-boton">
                        <a href="" onClick={downloadQRCode}>
                            <img src="images/dashboard-boton3.jpg" alt="Descargar QR" />
                        </a>
                        <QRCode
                            id="qr-gen"
                            value={`https://pido-online.com.ar/${!loadingCliente ? cliente.username : ''}`}
                            size={500}
                            level={"H"}
                            includeMargin={true}
                        />
                    </div>
                </div>
                <div className='row'>
                    {loadingCliente
                    ? <Spinner />
                    : cliente.expiration === null
                    ? <>
                        <div className={cliente.active ? 'dashboard-status status-active' : 'dashboard-status status-inactive'}>
                            {cliente.active ? 'Usuario activo' : 'Usuario inactivo'}
                        </div>
                        <div className='dashboard-status status-payday'>
                            {`Día de pago: ${cliente.payday === null ? 'No tiene asignado día de pago' : cliente.payday}`}
                        </div>
                    </> 
                    : <>
                        <div className={cliente.active ? 'dashboard-status status-active' : 'dashboard-status status-inactive'}>
                            {cliente.active ? 'Usuario activo' : 'Usuario inactivo'}
                        </div>
                        <div className='dashboard-status status-payday'>
                            {`Día de pago: ${cliente.payday === null ? 'No tiene asignado día de pago' : cliente.payday}`}
                        </div>
                        <div className='dashboard-status status-inactive'>
                            {`Fecha de expiración: ${format(new Date(cliente.expiration), 'dd-MM-yyyy HH:mm') + 'hs'}`}
                        </div>
                    </>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DashBoardPage