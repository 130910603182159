import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

const NotFoundPage = () => {
    const navigate = useNavigate()

    useEffect(() => {
        navigate('/')
    }, [])

    return <div>Not found</div>
}

export default NotFoundPage