import './Navbar.css';
import { useState } from 'react';
import { useAuth } from '../../context/AuthContext.js';

const Navbar = () => {
    const {isAuthenticated, logOut, user} = useAuth();
    const [isActiveUser, setIsActiveUser] = useState(false)

    const cerrarSesion = () => {
        logOut()
    }

    const redirigirDashboard = () => {
        window.location.href = 'https://admin.pido-online.com.ar'
    }

    const clickIconoUser = () => {
        setIsActiveUser(!isActiveUser)
    }

    document.addEventListener('click', function(event) {
        if(event.target.id != 'navbar-icono' && event.target.id != 'navbar-icono-user' && event.target.id != 'navbar-expand' && event.target.id != 'navbar-expand-cerrar-sesion' && event.target.id != 'navbar-expand-dashboard' && event.target.id != 'navbar-expand-username' && event.target.id != 'navbar-expand-username-span'){
            setIsActiveUser(false)
        }
    });

    return (
        <div className="navbar">
            <img src="./images/navbar.svg" alt='logo' />
            {
            isAuthenticated && 
            <div className='navbar-controls'>
                <div className='navbar-controls-username'>¡Hola <span>{user.username}</span>!</div>
                <div id='navbar-icono' onClick={clickIconoUser} className={isActiveUser ? 'navbar-icono navbar-active' : 'navbar-icono'}>
                    <img id='navbar-icono-user' className={isActiveUser ? 'navbar-icono-user navbar-active' : 'navbar-icono-user'} src='images/icono-user.png' alt='icono user' />
                </div>
                <div id='navbar-expand' className={isActiveUser ? 'navbar-expand navbar-active' : 'navbar-expand'}>
                    <div id='navbar-expand-username'>¡Hola <span id='navbar-expand-username-span'>{user.username}</span>!</div>
                    <div id='navbar-expand-cerrar-sesion' onClick={cerrarSesion}>Cerrar sesión</div>
                </div>
            </div>
            }
        </div>
    );
}

export default Navbar;